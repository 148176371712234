<template>
  <div>
    <div class="fin-reporter-logo row pb-3">
      <div class="col-sm-6">
        <!-- <img
          src="../../../../static/img/ethos/fis-print-logo.png"
          class="report-logo"
          v-if="partnerSite"
        /> 
         v-else -->
        <img src="/logo.png" class="report-logo" />
      </div>
    </div>

    <PageCardWithSettings
      pageName="FIN Reporter"
      :logoUrl="s3LogoUrl"
      :institutionId="selectedInstitution.id"
      :institutionName="selectedInstitution.name"
      :cardBodyClasses="['px-2', 'page-card-body']"
      :iconClass="['cuis-spreadsheet', 'card-icon-font']"
    >
      <template slot="page_content">
        <div v-if="reportReady">
          <component v-bind:is="currentMetric"></component>
        </div>
        <div v-else>
          <ReportGeneratorPlaceholder
            :progress="reportProgressValue"
          ></ReportGeneratorPlaceholder>
        </div>
      </template>
    </PageCardWithSettings>

    <div
      :class="[
        'single-metric-charts pt-3',
        { 'print-all': printWithSingleMetricsData }
      ]"
    >
      <div
        class="row mb-3 metric-chart-row no-gutters"
        v-for="(metrics, index) in groupedSingleMetricCharts"
        :key="index"
      >
        <div
          class="col-sm-6 chart-container"
          v-for="(singleMetric, metricIndex) in metrics"
          :key="metricIndex"
        >
          <BarChart
            :chartData="chartData(singleMetric)"
            :metricGroup="{ group_name: metricGroupName }"
            :metric="{ metric_name: singleMetric.name }"
            :renderedForPDF="true"
            :format="singleMetric.format"
            v-if="chartType === 'bar'"
          ></BarChart>
          <LineChart
            :chartData="chartData(singleMetric)"
            :metricGroup="{ group_name: metricGroupName }"
            :metric="{ metric_name: singleMetric.name }"
            :renderedForPDF="true"
            :format="singleMetric.format"
            v-else
          ></LineChart>
        </div>
      </div>
    </div>
    <landscape-mode></landscape-mode>
    <normal-zoom-mode></normal-zoom-mode>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'
import PageCardWithSettings from '@/modules/core/components/layouts/PageCardWithSettings'
import ReportGeneratorPlaceholder from '../components/ReportGeneratorPlaceholder'
import CustomMultiMetric from './CustomMultiMetric'
import CustomSingleMetric from './CustomSingleMetric'
import CustomPeriodMetric from './CustomPeriodMetric'
import BarChart from '../components/BarChart'
import LineChart from '../components/LineChart'
import LandscapeMode from '@/modules/core/components/LandscapeMode'
import NormalZoomMode from '@/modules/core/components/NormalZoomMode'
export default {
  name: 'Metrics',
  data() {
    return {}
  },
  components: {
    PageCardWithSettings,
    ReportGeneratorPlaceholder,
    CustomMultiMetric,
    CustomSingleMetric,
    CustomPeriodMetric,
    BarChart,
    LineChart,
    LandscapeMode,
    NormalZoomMode
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (
        vm.metric_args.reportId === null ||
        vm.metric_args.reportTypeId === null
      ) {
        vm.$router.push('/analytics/reporter')
        return
      }
      vm.$store.dispatch('setAside', true)
      document.title = 'FIN Reporter'
    })
  },
  beforeCreate() {
    this.$store.dispatch('updateBreakPointProgress')
    this.$store.dispatch('updateReportProgress')
    this.$store.commit('multi_metrics', { metricsList: [] })
    this.$store.commit('single_metrics', {
      metricsList: [],
      selectedMetric: null
    })
    this.$store.commit('period_metrics', {
      metricsList: [],
      selectedPeriod: null
    })

    this.$store.commit('multi_metrics', {
      metricGroupId: this.$store.getters.metric_args.reportTypeId
    })
    this.$store.commit('single_metrics', {
      metricGroupId: this.$store.getters.metric_args.reportTypeId
    })
    this.$store.commit('period_metrics', {
      metricGroupId: this.$store.getters.metric_args.reportTypeId
    })
    this.$store.commit('setPrintWithSingleMetricsData', false)
    this.$store.commit('setAllSingleMetricsData', null)
  },
  beforeDestroy() {
    document.body.classList.add('aside-menu-hidden')
    this.$store.dispatch('setAsideToogle', false)

    this.$store.commit('multi_metrics', { metricsList: [] })
    this.$store.commit('single_metrics', {
      metricsList: [],
      selectedMetric: null
    })
    this.$store.commit('period_metrics', {
      metricsList: [],
      selectedPeriod: null
    })
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters([
      's3LogoUrl',
      'selectedInstitution',
      'isMultiMetricView',
      'reportView',
      'metric_args',
      'multi_metrics',
      'isCustomReport',
      'chartType',
      'showPeers',
      'breakPointProgress',
      'reportProgress',
      'allSingleMetricsData',
      'printWithSingleMetricsData'
    ]),
    currentMetric() {
      const view_type =
        this.reportView.charAt(0).toUpperCase() + this.reportView.slice(1)
      return 'custom' + view_type + 'Metric'
    },
    reportProgressValue() {
      return ((this.breakPointProgress || 0) + (this.reportProgress || 0)) / 2
    },
    reportReady() {
      return this.reportProgressValue === 100
    },
    groupedSingleMetricCharts() {
      if (this.allSingleMetricsData) {
        let orderedMetrics = this.getOrderedMetrics()
        return _.chunk(orderedMetrics, 2)
      } else {
        return []
      }
    },
    chartLabels() {
      return _.map(this.allSingleMetricsData.headers, function(header) {
        return header[1]
      })
    },
    metricGroupName() {
      return this.allSingleMetricsData.metrics[0].group_name
    }
  },
  methods: {
    chartData(metric) {
      let metricPlotData = {
        backgroundColor: 'transparent',
        borderColor: '#7EAA55',
        data: metric.graph_plots
      }

      let peerPlotData = {
        backgroundColor: 'transparent',
        borderColor: '#A6A6A6',
        data: metric.peer_graph_plots
      }

      return {
        labels: this.chartLabels,
        datasets: [
          metricPlotData,
          ...(this.showPeers && peerPlotData.data ? [peerPlotData] : [])
        ]
      }
    },
    getOrderedMetrics() {
      let list = []

      if (this.allSingleMetricsData.element_format) {
        this.allSingleMetricsData.element_format.forEach(ef => {
          this.addElementToList(ef, list)
        })
      } else {
        list = this.allSingleMetricsData.metrics[0].metric_values
      }

      return list
    },
    addElementToList(formattedElement, list) {
      let matchingMetric = this.getMatchingElement(formattedElement)

      if (matchingMetric) {
        list.push(matchingMetric)
      }

      if (formattedElement.children && formattedElement.children.length) {
        formattedElement.children.forEach(child => {
          this.addElementToList(child, list)
        })
      }
    },
    getMatchingElement(formattedElement) {
      if (['space', 'header', 'lineBreak'].includes(formattedElement.type)) {
        return null
      }

      return this.allSingleMetricsData.metrics[0].metric_values.find(
        mv => mv.element_id === formattedElement.id
      )
    }
  }
}
</script>

<style lang="scss">
.fin-reporter-logo {
  display: none;
}

.single-metric-charts {
  display: none;

  .chart-container {
    border: solid 3px #e4e5e6;
  }
}

@media print {
  @page {
    size: A3;
  }
  .fin-reporter {
    &.card {
      border: solid 3px #e4e5e6;
    }
  }

  .fin-reporter-logo {
    display: block;
  }

  .metric-chart-row {
    page-break-inside: avoid;
  }

  .single-metric-charts {
    page-break-before: always;

    &.print-all {
      display: block;
    }
  }
}
</style>
